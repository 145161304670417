import { getHslAnimationFromHsl, randomInRange, rangeColorToHsl } from "tsparticles-engine";
function updateColorValue(delta, value, valueAnimation, max, decrease) {
    var _a, _b;
    const colorValue = value;
    if (!colorValue ||
        !valueAnimation.enable ||
        (colorValue.loops !== undefined &&
            colorValue.maxLoops !== undefined &&
            colorValue.maxLoops > 0 &&
            colorValue.loops >= colorValue.maxLoops)) {
        return;
    }
    const offset = randomInRange(valueAnimation.offset), velocity = ((_a = value.velocity) !== null && _a !== void 0 ? _a : 0) * delta.factor + offset * 3.6, decay = (_b = value.decay) !== null && _b !== void 0 ? _b : 1;
    if (!decrease || colorValue.status === "increasing") {
        colorValue.value += velocity;
        if (colorValue.value > max) {
            if (!colorValue.loops) {
                colorValue.loops = 0;
            }
            colorValue.loops++;
            if (decrease) {
                colorValue.status = "decreasing";
                colorValue.value -= colorValue.value % max;
            }
        }
    }
    else {
        colorValue.value -= velocity;
        if (colorValue.value < 0) {
            if (!colorValue.loops) {
                colorValue.loops = 0;
            }
            colorValue.loops++;
            colorValue.status = "increasing";
            colorValue.value += colorValue.value;
        }
    }
    if (colorValue.velocity && decay !== 1) {
        colorValue.velocity *= decay;
    }
    if (colorValue.value > max) {
        colorValue.value %= max;
    }
}
function updateColor(particle, delta) {
    var _a, _b, _c;
    const animationOptions = particle.options.color.animation;
    const h = (_a = particle.color) === null || _a === void 0 ? void 0 : _a.h, s = (_b = particle.color) === null || _b === void 0 ? void 0 : _b.s, l = (_c = particle.color) === null || _c === void 0 ? void 0 : _c.l;
    if (h) {
        updateColorValue(delta, h, animationOptions.h, 360, false);
    }
    if (s) {
        updateColorValue(delta, s, animationOptions.s, 100, true);
    }
    if (l) {
        updateColorValue(delta, l, animationOptions.l, 100, true);
    }
}
export class ColorUpdater {
    constructor(container) {
        this.container = container;
    }
    init(particle) {
        const hslColor = rangeColorToHsl(particle.options.color, particle.id, particle.options.reduceDuplicates);
        if (hslColor) {
            particle.color = getHslAnimationFromHsl(hslColor, particle.options.color.animation, this.container.retina.reduceFactor);
        }
    }
    isEnabled(particle) {
        var _a, _b, _c;
        const animationOptions = particle.options.color.animation;
        return (!particle.destroyed &&
            !particle.spawning &&
            ((((_a = particle.color) === null || _a === void 0 ? void 0 : _a.h.value) !== undefined && animationOptions.h.enable) ||
                (((_b = particle.color) === null || _b === void 0 ? void 0 : _b.s.value) !== undefined && animationOptions.s.enable) ||
                (((_c = particle.color) === null || _c === void 0 ? void 0 : _c.l.value) !== undefined && animationOptions.l.enable)));
    }
    update(particle, delta) {
        updateColor(particle, delta);
    }
}
